.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

.tippy-box[data-theme~="material"] {
  background-color: #505355;
  font-weight: 600;
}

.tippy-box[data-theme~="material"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: #505355;
}

.tippy-box[data-theme~="material"][data-placement^="bottom"] > .tippy-arrow:before {
  border-bottom-color: #505355;
}

.tippy-box[data-theme~="material"][data-placement^="left"] > .tippy-arrow:before {
  border-left-color: #505355;
}

.tippy-box[data-theme~="material"][data-placement^="right"] > .tippy-arrow:before {
  border-right-color: #505355;
}

.tippy-box[data-theme~="material"] > .tippy-backdrop {
  background-color: #505355;
}

.tippy-box[data-theme~="material"] > .tippy-svg-arrow {
  fill: #505355;
}

.tippy-box[data-animation="scale"][data-placement^="top"] {
  transform-origin: bottom;
}

.tippy-box[data-animation="scale"][data-placement^="bottom"] {
  transform-origin: top;
}

.tippy-box[data-animation="scale"][data-placement^="left"] {
  transform-origin: 100%;
}

.tippy-box[data-animation="scale"][data-placement^="right"] {
  transform-origin: 0;
}

.tippy-box[data-animation="scale"][data-state="hidden"] {
  opacity: 0;
  transform: scale(.5);
}

@font-face {
  font-family: remixicon;
  src: url("remixicon.72580493.eot");
  src: url("remixicon.72580493.eot#iefix") format("embedded-opentype"), url("remixicon.19c261ad.woff2") format("woff2"), url("remixicon.d9bd5bad.woff") format("woff"), url("remixicon.af7a00e5.ttf") format("truetype"), url("remixicon.47b305c9.svg#remixicon") format("svg");
  font-display: swap;
}

[class^="ri-"], [class*="ri-"] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-family: remixicon !important;
}

.ri-lg {
  vertical-align: -.0667em;
  font-size: 1.3333em;
  line-height: .75em;
}

.ri-xl {
  vertical-align: -.075em;
  font-size: 1.5em;
  line-height: .6666em;
}

.ri-xxs {
  font-size: .5em;
}

.ri-xs {
  font-size: .75em;
}

.ri-sm {
  font-size: .875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-search-eye-line:before {
  content: "";
}

.ri-question-line:before {
  content: "";
}

.ri-settings-4-line:before {
  content: "";
}

.ri-user-star-line:before {
  content: "";
}

.ri-login-circle-fill:before {
  content: "";
}

.ri-draft-fill:before {
  content: "";
}

.ri-linkedin-box-fill:before {
  content: "";
}

.ri-facebook-box-fill:before {
  content: "";
}

.ri-instagram-fill:before {
  content: "";
}

.ri-vimeo-fill:before {
  content: "";
}

.ri-youtube-fill:before {
  content: "";
}

.ri-arrow-down-circle-fill:before {
  content: "";
}

.ri-arrow-up-circle-fill:before {
  content: "";
}

.ri-arrow-down-circle-line:before {
  content: "";
}

html, body {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  background-color: #a5abb2;
  margin: 0;
  padding: 0;
  font-family: modelica, sans-serif;
  overflow: hidden;
}

section {
  margin-top: -1px;
}

#container-load {
  z-index: 100;
  opacity: 1;
  background-color: #a5abb2;
  outline: none;
  transition: opacity 1s;
  position: fixed;
}

.loadingtext {
  color: #4c546b;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-family: modelica, sans-serif;
  font-size: 1.5em;
  display: flex;
  position: absolute;
  bottom: 4%;
}

#loadingscreen {
  width: 100vw;
}

#pin-overlay {
  width: 100vw;
  opacity: 0;
  transform-box: fill-box;
  transform-origin: 47.8%;
  z-index: 2;
}

#logo {
  width: 300px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes scroll-2 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 0;
    transform: translateY(.75em);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.scrollenv {
  opacity: 0;
  margin: 0;
  font-size: smaller;
  display: flex;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.scroll-icon-dot {
  height: .5em;
  width: .5em;
  transform-origin: top;
  backface-visibility: hidden;
  background: #ed1b33;
  border-radius: 50%;
  margin-left: -.25em;
  animation: 2s ease-out infinite scroll-2;
  display: block;
  position: absolute;
  top: .6em;
  left: 50%;
}

.scroll-icon {
  height: 3em;
  width: 1.5em;
  border: .15em solid #4c546b;
  border-radius: 1em;
  display: block;
  position: relative;
}

#bob {
  z-index: 2;
  height: calc(var(--vh) * 100);
  width: 100vw;
  pointer-events: none;
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
}

.scroller {
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

#container {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.bg {
  background: url("back.20ad6ac4.webp") center / cover;
}

#wavetop {
  width: 100vw;
  height: calc(var(--vh) * 100);
  background: url("Wavestudio3.3bd620f8.svg") bottom / cover no-repeat;
  position: absolute;
  bottom: 0;
}

#wavest {
  width: 100vw;
  height: calc(var(--vh) * 100);
  background: url("Wavestudio2.42b98ae3.svg") bottom / cover no-repeat;
  position: absolute;
}

.panel {
  width: 100%;
  height: calc(var(--vh) * 100);
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.panel1 {
  color: #f5f5f5;
  width: 100%;
  height: calc(var(--vh) * 100);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.quote {
  background-color: #0000;
}

.quote .quote-text h1 {
  text-transform: uppercase;
  opacity: 0;
  margin: 0 auto;
  padding: 0;
  font-family: modelicabold, sans-serif;
  line-height: 1.2;
  position: relative;
}

.quote .quote-text h1 .quote1 {
  font-size: clamp(1.3rem, 3.6vw, 60px);
}

.quote .quote-text h1 .quote2 {
  font-size: clamp(2rem, 6vw, 100px);
}

.dots {
  z-index: 2;
  height: clamp(.5rem, 1.2vw, 20px);
  width: clamp(.5rem, 1.2vw, 20px);
  opacity: 0;
  background-color: #f5f5f5;
  border-radius: 50%;
  margin: max(3rem, 5%) auto 0;
  display: flex;
  position: relative;
}

.header {
  z-index: 2;
  text-align: center;
  line-height: clamp(1rem, .8);
  margin: 20px auto 10px;
  position: relative;
}

.title-1 {
  color: #1b1b1d;
  opacity: 0;
  pointer-events: none;
  font-family: modelicabold, sans-serif;
  font-size: clamp(3rem, 12vw, 120px);
}

.title-2 {
  width: 60%;
  color: #1b1b1d;
  margin: 0 auto;
  font-size: clamp(1.4rem, 3vw, 40px);
  font-weight: 400;
}

.in1 {
  z-index: 3;
  height: 0;
  text-align: left;
  color: #1b1b1d;
  width: 60%;
  background: #adadff;
  border-radius: 25px;
  margin: 0 auto;
  padding: 0;
  font-size: clamp(1rem, 2vw, 20px);
  line-height: 1.3;
  position: relative;
  box-shadow: inset 15px 15px 30px #9c9ce6, inset -15px -15px 30px #bebeff;
}

.in2 {
  z-index: 3;
  height: 0;
  text-align: left;
  color: #1b1b1d;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 60%;
  background: #00d9ff83;
  border-radius: 25px;
  margin: 0 auto;
  padding: 0;
  font-size: clamp(1rem, 2vw, 20px);
  line-height: 1.3;
  position: relative;
  box-shadow: inset 15px 15px 30px #00b8d9, inset -15px -15px 30px #00faff;
}

.in3 {
  z-index: 3;
  height: 0;
  text-align: left;
  color: #f5f5f5;
  width: 60%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #6666ff80;
  border-radius: 25px;
  margin: 0 auto;
  padding: 0;
  font-size: clamp(1rem, 2vw, 20px);
  line-height: 1.3;
  position: relative;
  box-shadow: inset 15px 15px 30px #5757d9, inset -15px -15px 30px #7575ff;
}

.inf1, .inf2, .inf3 {
  opacity: 0;
  pointer-events: none;
  padding: 30px;
}

.b1 {
  z-index: 3;
  color: #fff;
  background-color: #0000;
  border: none;
  margin: 0 auto;
  padding-top: 25px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.b2, .b3 {
  z-index: 3;
  color: #fff;
  background-color: #0000;
  border: none;
  margin: 0 auto;
  padding-top: 25px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.b1, .b2, .b3 span {
  margin: 0 auto;
  font-family: modelicabold, sans-serif;
  font-size: 15px;
}

@media screen and (max-width: 600px) {
  .title-1 {
    width: 95%;
    text-align: center;
    pointer-events: none;
    margin: 30px auto;
  }

  .title-2 {
    width: 90%;
    text-align: center;
    pointer-events: none;
    margin: 10px auto;
  }

  .inf1, .inf2, .inf3 {
    padding: 20px;
  }

  .in1, .in2, .in3 {
    width: 90%;
  }
}

.studio {
  min-height: calc(var(--vh) * 100);
  width: 100%;
  background-image: linear-gradient(0deg, #adadff 0%, #9eb3ff 21%, #8fb8ff 30%, #7abdff 39%, #6bc4ff 46%, #57c7ff 54%, #42cdff 61%, #2ed2ff 69%, #1ad5ff 79%, #00d9ff 100%);
  font-family: modelica, sans-serif;
  overflow: hidden;
}

.box p {
  color: #f5f5f5;
  text-align: left;
  word-spacing: 1px;
  line-height: 1.6;
}

.box {
  z-index: 3;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #00000013;
  border: 1px solid #fefefe40;
  border-width: 1px 2px;
  border-radius: 25px;
  margin: 20px;
  padding: 30px;
  overflow: hidden;
  box-shadow: inset 9px 9px 50px #ffffff26, 2px 8px 8px #0000004d;
}

.backline {
  width: 115%;
  box-sizing: border-box;
  background: #ffffff4d;
}

@media (max-width: 450px) {
  .box {
    height: 280px;
  }
}

@media (max-width: 950px) and (min-width: 450px) {
  .box {
    text-align: left;
    height: 200px;
  }
}

.studio .studio h3 {
  color: #1b1b1d;
  padding-left: 5px;
  font-size: clamp(1.1rem, 1.5vw, 22px);
  font-weight: 600;
}

.studio i {
  padding: 5px;
  font-size: 20px;
}

@media (min-width: 950px) {
  .studio .row1-container, .row2-container {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .box-down {
    position: relative;
    top: 150px;
  }

  .box {
    width: 20%;
    position: relative;
  }

  .header p {
    width: 55%;
  }
}

#teamc {
  z-index: 1;
  overflow: hidden;
}

#team {
  pointer-events: none;
  height: calc(var(--vh) * 100);
  width: 100vw;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
}

.team {
  min-height: calc(var(--vh) * 100);
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#adadff 0%, #9eb3ff 21%, #8fb8ff 30%, #7abdff 39%, #6bc4ff 46%, #57c7ff 54%, #42cdff 61%, #2ed2ff 69%, #1ad5ff 79%, #00d9ff 100%);
  flex-flow: column wrap;
  place-content: normal;
  place-items: normal;
  font-family: modelica, sans-serif;
  display: flex;
  position: relative;
}

.img {
  width: 50%;
  position: relative;
  top: -85px;
}

.team .card:hover img {
  animation: 6s linear infinite both fade;
}

@keyframes fade {
  0%, 50%, 100% {
    filter: drop-shadow(0 0 40px #7bff0000);
  }

  25%, 75% {
    filter: drop-shadow(0 0 40px #7bff00);
  }
}

.team .row1-container {
  width: 90vw;
  height: 100%;
  z-index: 3;
  grid-template: "frank farah farinaz" 1fr
  / 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  place-content: center;
  place-items: center;
  gap: 20px;
  margin: 25px auto 0;
  display: grid;
  position: relative;
}

.teamtext1, .teamtext2 {
  pointer-events: none;
}

.team .card {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 3;
  background-color: #00000013;
  border: 1px solid #fefefe40;
  border-width: 1px 2px;
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: modelica, sans-serif;
  box-shadow: inset 9px 9px 50px #ffffff26, 2px 8px 8px #0000004d;
}

.top-section {
  position: relative;
}

.backname {
  width: 100%;
  box-sizing: border-box;
  background: #333;
}

.name {
  color: #f5f5f5;
  opacity: .8;
  font-size: 50px;
  line-height: .8;
  position: absolute;
  bottom: 0;
  left: 0;
}

.name span {
  background: #333;
  padding: 0 5px;
  font-size: 25px;
}

.name span:first-of-type {
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 600;
}

.info-section {
  color: #f5f5f5;
  padding: 0 30px 30px;
}

.info-section h4 {
  color: #fff;
  padding: .5rem 0;
  font-size: 1.5rem;
}

.info-section p {
  text-align: left;
  line-height: 1.6;
}

.social {
  justify-content: space-evenly;
  display: flex;
}

.social svg {
  fill: #4c546b;
  z-index: 4;
  transition: fill .8s;
  position: relative;
}

@media screen and (max-width: 950px) {
  .img {
    width: 100%;
    margin: 0 auto;
    top: 8px;
  }

  .team .row1-container {
    width: 90vw;
    height: 100%;
    grid: "frank" 1fr
          "farah" 1fr
          "farinaz" 1fr
          / 1fr;
    place-content: stretch center;
    place-items: stretch center;
    gap: 10px;
    margin: 0 auto;
    display: grid;
  }

  .frank {
    width: 100%;
    height: 100%;
    grid-area: frank;
    position: relative;
  }

  .farah {
    width: 100%;
    height: 100%;
    grid-area: farah;
    position: relative;
  }

  .farinaz {
    width: 100%;
    height: 100%;
    grid-area: farinaz;
    position: relative;
  }
}

.services {
  min-height: calc(var(--vh) * 100);
  height: 100%;
  width: 100vw;
  box-sizing: border-box;
  background-image: linear-gradient(#00d9ff 0%, #00d0ff 21%, #00c8ff 30%, #0bf 39%, #00b2ff 46%, #00a6ff 54%, #09f 61%, #08f 69%, #3d7bff 79%, #66f 100%);
  border: 0;
  flex-flow: column wrap;
  place-content: stretch;
  place-items: stretch stretch;
  margin: 0;
  padding: 0;
  font-family: modelica, sans-serif;
  display: flex;
  position: relative;
}

.servicestext1, .servicestext2 {
  pointer-events: none;
}

@media screen and (min-width: 1550px) {
  .hidebreak {
    display: none;
  }
}

.services .row1-container {
  z-index: 3;
  width: 95vw;
  flex-wrap: nowrap;
  margin: 0 auto;
  display: flex;
}

.services .card {
  height: auto;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  background-color: #00000013;
  border: 1px solid #fefefe40;
  border-width: 1px 2px;
  border-radius: 25px;
  margin: 10px;
  padding: 20px;
  font-family: modelica, sans-serif;
  overflow: hidden;
  box-shadow: inset 9px 9px 50px #ffffff26, 2px 8px 8px #0000004d;
}

.services .card h3 {
  color: #1b1b1d;
  padding-left: 5px;
  font-family: modelicabold, sans-serif;
  font-size: clamp(2.1rem, 3.1vw, 78px);
}

.services .card h4 {
  color: #1b1b1d;
  z-index: 3;
  font-size: 1.8rem;
}

.services .card h5 {
  color: #1b1b1d;
  z-index: 3;
  font-size: 1.2rem;
}

.services .card p {
  color: #f5f5f5;
  line-height: 1.6;
}

.servicesbottomtext {
  color: #1b1b1d;
  font-size: 1.2rem;
}

.projects {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: #66f;
  flex-wrap: wrap;
  font-family: modelica, sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
}

.projectstext1 {
  pointer-events: none;
}

.projectstext2 {
  pointer-events: none;
  padding-bottom: 20px;
}

.pmenu {
  color: #fff;
  z-index: 3;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.pmenu .filter {
  width: 100px;
  cursor: pointer;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #00000013;
  border: 1px solid #fefefe40;
  border-width: 1px 2px;
  border-radius: 25px;
  margin: 10px 20px;
  padding: 10px;
  font-family: modelicabold, sans-serif;
  transition: all .4s;
  box-shadow: inset 9px 9px 50px #ffffff26, 2px 8px 8px #0000004d;
}

.pmenu .filter:hover {
  z-index: 1;
  background: linear-gradient(90deg, #00a19d, #0c87b7, #cb49ff, #f66, #00a19d) 0 0 / 600%;
  border-color: #0000;
  animation: 20s linear forwards glow;
}

@keyframes glow {
  from {
    background-position: 0%;
  }

  to {
    background-position: 600%;
  }
}

.pmenu .filter:active {
  transform: translateY(4px);
  box-shadow: inset 9px 9px 50px #ffffff26, 0 0 2px #0000004d;
}

.pmenu .factive {
  background-color: #ed1b3369;
}

.pmenu .filter:before {
  content: "";
  background: inherit;
  filter: blur(20px);
  opacity: 0;
  transition: all .4s ease-out;
  position: absolute;
  inset: -1px;
}

.pmenu .filter:hover:before {
  opacity: 1;
  z-index: -1;
}

.projectcon .item img {
  z-index: -2;
  opacity: 1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-image: linear-gradient(to bottom left, #d6d6d6, #dadada, #ddd, #e1e1e1, #e5e5e5);
  border-radius: 25px;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.projectcon .item .bootimg, .projectcon .item .duraimg {
  object-fit: contain;
}

.projectcon {
  width: 100vw;
  min-height: -moz-fit-content;
  min-height: fit-content;
  z-index: 3;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 40px;
  display: inline-flex;
}

.projectcon .item {
  width: 400px;
  height: 400px;
  text-align: center;
  cursor: pointer;
  border-radius: 25px;
  transition: box-shadow .5s ease-in-out;
  overflow: hidden;
}

.placeholder {
  display: unset;
  width: 400px;
  height: 400px;
  margin: 20px;
  transition: all .5s ease-in-out;
}

.boxtext {
  width: 100%;
  color: #1b1b1d;
  background-color: #f5f5f5;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 12px 0;
  font-family: modelicabold, sans-serif;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.copy {
  color: #000;
  text-align: center;
  margin: auto;
  padding: 2px;
  font-family: modelicabold, sans-serif;
  font-size: 18px;
  overflow: hidden;
}

.iframe {
  height: calc(var(--vh) * 100);
  width: 100vw;
  opacity: 0;
  border: none;
  transition: all 1s;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50.1%);
}

.iframelog {
  height: 85%;
  width: 100%;
  opacity: 1;
  border: none;
  transition: all 1s;
  overflow-y: scroll;
}

.cursor {
  z-index: 998;
  width: 40px;
  height: 40px;
  pointer-events: none;
  background-color: #ffffff59;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
}

.dot {
  z-index: 999;
  width: 8px;
  height: 8px;
  pointer-events: none;
  background-color: #ed1b33;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
}

.dotc {
  fill: #ed1b33;
}

header {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  will-change: transform;
  width: clamp(180px, 40%, 250px);
  z-index: 20;
  opacity: 0;
  background-color: #ffffff1a;
  border: 1px solid #ffffff20;
  border-bottom-right-radius: 26px;
  padding: 10px 2px 10px 10px;
  transition: all .5s;
  position: fixed;
  top: -300px;
  left: 0;
  box-shadow: 0 0 5px 1px #00000063;
}

@media screen and (min-width: 2500px) {
  .cursor {
    width: 80px;
    height: 80px;
  }

  .dot {
    width: 16px;
    height: 16px;
  }

  .pmenu .filter {
    width: 200px;
    font-size: 30px;
  }

  .copy {
    font-size: 25px;
  }

  .services .card h2 {
    font-size: 80px;
  }

  .services .card h3 {
    font-size: 45px;
  }

  .services .card p {
    font-size: 30px;
  }

  .team .card {
    height: 100%;
    padding-bottom: 20px;
  }

  .img {
    top: -160px;
  }

  .name {
    line-height: 1.2;
    top: 77%;
  }

  .name span {
    font-size: 75px;
  }

  .name span:first-of-type {
    font-size: 120px;
  }

  .info-section {
    padding: 0 30px 30px;
  }

  .info-section h2 {
    padding: 5rem 0 0;
    font-size: 50px;
  }

  .info-section p {
    font-size: 30px;
  }

  .social svg {
    width: 80px;
    height: auto;
  }

  .in1, .in2, .in3, .box p {
    font-size: 30px;
  }

  .studio h2, .studio i {
    font-size: 40px;
  }

  .header {
    line-height: clamp(1rem, .8);
    margin: 20px auto 10px;
  }

  .title-1 {
    font-size: 200px;
  }

  .title-2 {
    width: 60%;
    font-size: 80px;
    font-weight: 400;
  }

  .quote .quote-text h1 .quote1 {
    font-size: 6.3rem;
    line-height: 1.2;
  }

  .quote .quote-text h1 .quote2 {
    font-size: 10.7rem;
  }

  .dots {
    height: 50px;
    width: 50px;
    margin: 10% auto 0;
  }

  .scroll-icon-dot {
    height: 1.5em;
    width: 1.5em;
    margin-left: -.75em;
    top: 1em;
  }

  .scroll-icon {
    height: 6em;
    width: 3em;
    border: .25em solid #4c546b;
    border-radius: 5em;
  }

  .loadingtext {
    font-size: 80px;
  }

  header {
    top: -270px;
  }
}

header ul {
  margin: 0;
  padding: 10px 0;
  list-style-type: none;
}

header li {
  padding: 10px 10px 10px 0;
  font-family: modelicabold, sans-serif;
}

header a {
  color: #ed1b33;
  font-size: clamp(1rem, .8vw, 1.2rem);
  text-decoration: none;
  transition: all .5s;
}

.mobileheader {
  background-color: #fff4;
  top: 0;
}

.down {
  font-size: clamp(2rem, 2vw, 40px);
}

#menu {
  color: #ed1b33;
  opacity: 1;
  padding: clamp(5px, 1vw, 10px);
  transition: all 1s;
  position: relative;
  bottom: clamp(8px, 1vw, 15px);
}

#modallog, #language {
  color: #ed1b33;
  cursor: pointer;
  font-size: clamp(1rem, .8vw, 1.2rem);
  transition: all .5s;
}

.modal {
  opacity: 0;
  width: 100vw;
  height: calc(var(--vh) * 100);
  z-index: 4;
  background-color: #0000;
  border: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-content {
  width: 100vw;
  height: calc(var(--vh) * 100);
  background-color: #fefefe00;
  border: none;
  padding: 0;
  overflow: hidden;
}

.close {
  z-index: 8;
  color: #ed1b33;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  float: right;
  cursor: pointer;
  background-color: #00000013;
  border: 1px solid #fefefe40;
  border-width: 1px 2px;
  border-radius: 25px;
  padding: 10px;
  font-family: modelicabold, sans-serif;
  font-size: 20px;
  font-weight: bold;
  transition: all .4s;
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: inset 9px 9px 50px #ffffff26, 2px 8px 8px #0000004d;
}

.close:hover {
  z-index: 1;
  color: #333;
  background: linear-gradient(90deg, #00a19d, #0c87b7, #cb49ff, #f66, #00a19d) 0 0 / 600%;
  border-color: #0000;
  animation: 20s linear forwards glow;
}

.close:active {
  transform: translateY(4px);
  box-shadow: inset 9px 9px 50px #ffffff26, 0 0 2px #0000004d;
}

.close:before {
  content: "";
  background: inherit;
  filter: blur(16px);
  opacity: 0;
  transition: all .4s ease-out;
  position: absolute;
  inset: -1px;
}

.close:hover:before {
  opacity: 1;
  z-index: -1;
}

.switch {
  --duration: .75s;
  --delay: .1s;
  --timing-function: linear;
  --transition: var(--duration) var(--delay) var(--timing-function);
  --switch-height: 30px;
  --switch-width: 60px;
  --switch-circle-size: var(--switch-height);
  --yellow: #acaeff;
  --orange: #ed1b33;
  --blue: #779bb2;
  cursor: pointer;
  width: var(--switch-width);
  height: var(--switch-height);
  box-shadow: -.4px 0 .5px -.1px #000000e8, 0 0 .5px .3px #b78729, 0 0 0 5px var(--yellow), 0 -.3px 0 5px #ffca60, 0 10px 11px 2px #00000078;
  white-space: nowrap;
  transition: var(--transition);
  -webkit-tap-highlight-color: transparent;
  border-radius: 100px;
  margin: 10px auto 25px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

.switchbg {
  --switch-bg-width: calc(var(--switch-circle-size)  + 20px);
  --switch-bg-height: var(--switch-height);
  width: var(--switch-bg-width);
  height: var(--switch-bg-height);
  transform: translateX(calc(var(--switch-circle-size) * -1));
  transition: var(--transition);
  display: inline-block;
}

.switchbg-enable {
  background-color: var(--blue);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: inset 5px 8px 10px -7px #4d4d4d82;
}

.switchbg-disable {
  background-color: var(--orange);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: inset -5px 8px 10px -7px #4d4d4d82;
}

.switchcircle {
  width: var(--switch-circle-size);
  height: var(--switch-circle-size);
  white-space: nowrap;
  transition: var(--transition);
  border-radius: 50%;
  position: absolute;
  top: 0;
  overflow: hidden;
  box-shadow: 0 0 20px #4d4d4d82;
}

.switch-circlebg {
  width: var(--switch-circle-size);
  height: var(--switch-circle-size);
  transition: var(--transition);
  display: inline-block;
  position: relative;
}

.switch-circlebg-enable {
  background-color: var(--blue);
  transform: translateX(calc(var(--switch-circle-size) * -.5));
  box-shadow: inset 2px -4px 14px -11px #000, inset -4px 11px 12px -10px #a3d2f2;
}

.switch-circlebg-disable {
  background-color: var(--orange);
  transform: translateX(calc(var(--switch-circle-size) * -1));
  box-shadow: inset -2px -4px 14px -11px #000, inset 4px 11px 12px -10px #f2804e;
}

.switch.switch-active {
  box-shadow: .4px 0 .5px -.1px #000000e8, 0 0 .5px .3px #b78729, 0 0 0 5px var(--yellow), 0 -.3px 0 5px #ffca60, 0 10px 11px 2px #00000078;
}

.switch.switch-active .switchcircle {
  transform: translate(var(--switch-circle-size));
}

.switch.switch-active .switchbg-enable, .switch.switch-active .switchbg-disable {
  transform: translate(0);
}

.switch.switch-active .switch-circlebg-disable {
  animation: var(--duration) var(--timing-function) circledisableactive forwards;
}

.switch.switch-active .switch-circlebg-enable {
  animation: var(--duration) var(--timing-function) circleenableactive forwards;
}

.switch.switch-not-active .switch-circlebg-disable {
  animation: var(--duration) var(--timing-function) circledisablenotactive forwards;
}

.switch.switch-not-active .switch-circlebg-enable {
  animation: var(--duration) var(--timing-function) circleenablenotactive forwards;
}

@keyframes circleenableactive {
  0% {
    z-index: 1;
    border-radius: 0% / 50%;
  }

  50% {
    transform: translateX(calc(var(--switch-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 2;
    border-radius: 50%;
    transform: translateX(0);
  }
}

@keyframes circledisableactive {
  0% {
    z-index: 2;
    border-radius: 50%;
  }

  50% {
    transform: translateX(calc(var(--switch-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 1;
    transform: translateX(calc(var(--switch-circle-size) * -.5));
    border-radius: 0% / 50%;
  }
}

@keyframes circleenablenotactive {
  0% {
    z-index: 2;
    border-radius: 50%;
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(var(--switch-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 1;
    border-radius: 0% / 50%;
  }
}

@keyframes circledisablenotactive {
  0% {
    z-index: 1;
    border-radius: 0% / 50%;
  }

  50% {
    transform: translateX(calc(var(--switch-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 2;
    transform: translateX(calc(var(--switch-circle-size) * -1));
    border-radius: 50%;
  }
}

.switch2 {
  z-index: 3;
  --duration2: .75s;
  --delay2: .1s;
  --timing-function2: linear;
  --transition2: var(--duration2) var(--delay2) var(--timing-function2);
  --switch2-height: 30px;
  --switch2-width: 60px;
  --switch2-circle-size: var(--switch2-height);
  --yellow2: #04d8ff;
  --orange2: #ed1b33;
  --blue2: #779bb2;
  cursor: pointer;
  width: var(--switch2-width);
  height: var(--switch2-height);
  box-shadow: -.4px 0 .5px -.1px #000000e8, 0 0 .5px .3px #b78729, 0 0 0 5px var(--yellow2), 0 -.3px 0 5px #ffca60, 0 10px 11px 2px #00000078;
  white-space: nowrap;
  transition: var(--transition2);
  -webkit-tap-highlight-color: transparent;
  border-radius: 100px;
  margin: 10px auto 25px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

.switch2bg {
  --switch2-bg-width: calc(var(--switch2-circle-size)  + 20px);
  --switch2-bg-height: var(--switch2-height);
  width: var(--switch2-bg-width);
  height: var(--switch2-bg-height);
  transform: translateX(calc(var(--switch2-circle-size) * -1));
  transition: var(--transition2);
  display: inline-block;
}

.switch2bg-enable {
  background-color: var(--blue2);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: inset 5px 8px 10px -7px #4d4d4d82;
}

.switch2bg-disable {
  background-color: var(--orange2);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: inset -5px 8px 10px -7px #4d4d4d82;
}

.switch2circle {
  width: var(--switch2-circle-size);
  height: var(--switch2-circle-size);
  white-space: nowrap;
  transition: var(--transition2);
  border-radius: 50%;
  position: absolute;
  top: 0;
  overflow: hidden;
  box-shadow: 0 0 20px #4d4d4d82;
}

.switch2-circlebg {
  width: var(--switch2-circle-size);
  height: var(--switch2-circle-size);
  transition: var(--transition2);
  display: inline-block;
  position: relative;
}

.switch2-circlebg-enable {
  background-color: var(--blue2);
  transform: translateX(calc(var(--switch2-circle-size) * -.5));
  box-shadow: inset 2px -4px 14px -11px #000, inset -4px 11px 12px -10px #a3d2f2;
}

.switch2-circlebg-disable {
  background-color: var(--orange2);
  transform: translateX(calc(var(--switch2-circle-size) * -1));
  box-shadow: inset -2px -4px 14px -11px #000, inset 4px 11px 12px -10px #f2804e;
}

.switch2.switch2-active {
  box-shadow: .4px 0 .5px -.1px #000000e8, 0 0 .5px .3px #b78729, 0 0 0 5px var(--yellow2), 0 -.3px 0 5px #ffca60, 0 10px 11px 2px #00000078;
}

.switch2.switch2-active .switch2circle {
  transform: translate(var(--switch2-circle-size));
}

.switch2.switch2-active .switch2bg-enable, .switch2.switch2-active .switch2bg-disable {
  transform: translate(0);
}

.switch2.switch2-active .switch2-circlebg-disable {
  animation: var(--duration2) var(--timing-function2) circledisableactive2 forwards;
}

.switch2.switch2-active .switch2-circlebg-enable {
  animation: var(--duration2) var(--timing-function2) circleenableactive2 forwards;
}

.switch2.switch2-not-active .switch2-circlebg-disable {
  animation: var(--duration2) var(--timing-function2) circledisablenotactive2 forwards;
}

.switch2.switch2-not-active .switch2-circlebg-enable {
  animation: var(--duration2) var(--timing-function2) circleenablenotactive2 forwards;
}

@keyframes circleenableactive2 {
  0% {
    z-index: 1;
    border-radius: 0% / 50%;
  }

  50% {
    transform: translateX(calc(var(--switch2-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 2;
    border-radius: 50%;
    transform: translateX(0);
  }
}

@keyframes circledisableactive2 {
  0% {
    z-index: 2;
    border-radius: 50%;
  }

  50% {
    transform: translateX(calc(var(--switch2-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 1;
    transform: translateX(calc(var(--switch2-circle-size) * -.5));
    border-radius: 0% / 50%;
  }
}

@keyframes circleenablenotactive2 {
  0% {
    z-index: 2;
    border-radius: 50%;
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(var(--switch2-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 1;
    border-radius: 0% / 50%;
  }
}

@keyframes circledisablenotactive2 {
  0% {
    z-index: 1;
    border-radius: 0% / 50%;
  }

  50% {
    transform: translateX(calc(var(--switch2-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 2;
    transform: translateX(calc(var(--switch2-circle-size) * -1));
    border-radius: 50%;
  }
}

.switch3 {
  z-index: 3;
  --duration3: .75s;
  --delay3: .1s;
  --timing-function3: linear;
  --transition3: var(--duration3) var(--delay3) var(--timing-function3);
  --switch3-height: 30px;
  --switch3-width: 60px;
  --switch3-circle-size: var(--switch3-height);
  --yellow3: #5f68fd;
  --orange3: #ed1b33;
  --blue3: #779bb2;
  cursor: pointer;
  width: var(--switch3-width);
  height: var(--switch3-height);
  box-shadow: -.4px 0 .5px -.1px #000000e8, 0 0 .5px .3px #b78729, 0 0 0 5px var(--yellow3), 0 -.3px 0 5px #ffca60, 0 10px 11px 2px #00000078;
  white-space: nowrap;
  transition: var(--transition3);
  -webkit-tap-highlight-color: transparent;
  border-radius: 100px;
  margin: 10px auto 25px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

.switch3bg {
  --switch3-bg-width: calc(var(--switch3-circle-size)  + 20px);
  --switch3-bg-height: var(--switch3-height);
  width: var(--switch3-bg-width);
  height: var(--switch3-bg-height);
  transform: translateX(calc(var(--switch3-circle-size) * -1));
  transition: var(--transition3);
  display: inline-block;
}

.switch3bg-enable {
  background-color: var(--blue3);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: inset 5px 8px 10px -7px #4d4d4d82;
}

.switch3bg-disable {
  background-color: var(--orange3);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: inset -5px 8px 10px -7px #4d4d4d82;
}

.switch3circle {
  width: var(--switch3-circle-size);
  height: var(--switch3-circle-size);
  white-space: nowrap;
  transition: var(--transition3);
  border-radius: 50%;
  position: absolute;
  top: 0;
  overflow: hidden;
  box-shadow: 0 0 20px #4d4d4d82;
}

.switch3-circlebg {
  width: var(--switch3-circle-size);
  height: var(--switch3-circle-size);
  transition: var(--transition3);
  display: inline-block;
  position: relative;
}

.switch3-circlebg-enable {
  background-color: var(--blue3);
  transform: translateX(calc(var(--switch3-circle-size) * -.5));
  box-shadow: inset 2px -4px 14px -11px #000, inset -4px 11px 12px -10px #a3d2f2;
}

.switch3-circlebg-disable {
  background-color: var(--orange3);
  transform: translateX(calc(var(--switch3-circle-size) * -1));
  box-shadow: inset -2px -4px 14px -11px #000, inset 4px 11px 12px -10px #f2804e;
}

.switch3.switch3-active {
  box-shadow: .4px 0 .5px -.1px #000000e8, 0 0 .5px .3px #b78729, 0 0 0 5px var(--yellow3), 0 -.3px 0 5px #ffca60, 0 10px 11px 2px #00000078;
}

.switch3.switch3-active .switch3circle {
  transform: translate(var(--switch3-circle-size));
}

.switch3.switch3-active .switch3bg-enable, .switch3.switch3-active .switch3bg-disable {
  transform: translate(0);
}

.switch3.switch3-active .switch3-circlebg-disable {
  animation: var(--duration3) var(--timing-function3) circledisableactive3 forwards;
}

.switch3.switch3-active .switch3-circlebg-enable {
  animation: var(--duration3) var(--timing-function3) circleenableactive3 forwards;
}

.switch3.switch3-not-active .switch3-circlebg-disable {
  animation: var(--duration3) var(--timing-function3) circledisablenotactive3 forwards;
}

.switch3.switch3-not-active .switch3-circlebg-enable {
  animation: var(--duration3) var(--timing-function3) circlenablenotactive3 forwards;
}

@keyframes circleenableactive3 {
  0% {
    z-index: 1;
    border-radius: 0% / 50%;
  }

  50% {
    transform: translateX(calc(var(--switch3-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 2;
    border-radius: 50%;
    transform: translateX(0);
  }
}

@keyframes circledisableactive3 {
  0% {
    z-index: 2;
    border-radius: 50%;
  }

  50% {
    transform: translateX(calc(var(--switch3-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 1;
    transform: translateX(calc(var(--switch3-circle-size) * -.5));
    border-radius: 0% / 50%;
  }
}

@keyframes circlenablenotactive3 {
  0% {
    z-index: 2;
    border-radius: 50%;
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(var(--switch3-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 1;
    border-radius: 0% / 50%;
  }
}

@keyframes circledisablenotactive3 {
  0% {
    z-index: 1;
    border-radius: 0% / 50%;
  }

  50% {
    transform: translateX(calc(var(--switch3-circle-size) * -.5));
    border-radius: 0% / 50%;
  }

  100% {
    z-index: 2;
    transform: translateX(calc(var(--switch3-circle-size) * -1));
    border-radius: 50%;
  }
}

.scrolltext:before {
  content: "SCROLL";
}

.downtext:before {
  content: "DOWN";
}

@media (pointer: coarse) {
  @keyframes scroll-2 {
    100% {
      opacity: 1;
      transform: translateY(0);
    }

    75% {
      opacity: 1;
    }

    25% {
      opacity: 0;
      transform: translateY(.75em);
    }

    0% {
      opacity: 0;
      transform: translateY(0);
    }
  }

  .cursor, .dot {
    display: none;
  }

  .scrolltext:before {
    content: "LIFT";
  }

  .downtext:before {
    content: "UP";
  }
}

.full-screen {
  left: 0;
  top: var(--ofy);
  width: 100vw;
  height: calc(var(--vh) * 100);
  min-width: 100vw;
  min-height: calc(var(--vh) * 100);
  z-index: 5;
  border-radius: 0;
  position: fixed;
}

.scrolltop {
  opacity: 0;
  color: #ed1b3371;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;
  background-color: #00000013;
  border: 1px solid #fefefe40;
  border-width: 1px 2px;
  border-radius: 50%;
  padding: 5px;
  font-size: 40px;
  transition: all .4s;
  position: absolute;
  bottom: 20px;
  right: 20px;
  box-shadow: inset 9px 9px 50px #ffffff26, 0 2px 4px #0000004d;
}

@media (pointer: fine) and (hover: hover) {
  header:hover {
    background-color: #fff9;
    top: 0;
  }

  header li:hover a, #modallog:hover, #language:hover {
    color: #4c546b;
  }

  .projectcon .item:hover img {
    height: 120%;
    width: 120%;
    top: -10%;
  }

  .projectcon .item:hover {
    box-shadow: 0 0 15px 7px #fff, 0 0 25px 15px #f0f, 0 0 35px 22px #0ff;
  }

  .social svg:hover {
    fill: #ed1b33;
    filter: drop-shadow(0 0 15px #fff);
  }

  .scrolltop:hover {
    color: #ed1b33;
  }
}

.scrolltop:active {
  box-shadow: none;
  transform: translateY(4px);
}

.modallog {
  opacity: 0;
  z-index: 10;
  width: 100%;
  height: calc(var(--vh) * 100);
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  background-color: #00000013;
  border: 1px solid #fefefe40;
  border-width: 1px 2px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: inset 9px 9px 50px #ffffff26, 0 2px 4px #0000004d;
}

.modal-contentlog {
  z-index: 12;
  transform: translate(-50% -50%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 50%;
  height: calc(var(--vh) * 80);
  background-color: #fff6;
  border: 1px solid #fefefe40;
  border-width: 1px 2px;
  border-radius: 25px;
  position: absolute;
  top: 45%;
  left: 50%;
  box-shadow: inset 9px 9px 50px #ffffff26, 0 2px 4px #0000004d;
}

.closelog {
  color: #ed1b33;
  float: right;
  padding: 25px;
  font-size: 20px;
  font-weight: bold;
}

.closelog:hover, .closelog:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 950px) {
  .header {
    width: 100vw;
  }

  .projectcon .item {
    width: 150px;
    height: 150px;
  }

  .placeholder {
    display: unset;
    width: 150px;
    height: 150px;
    margin: 5px;
  }

  .projectcon {
    width: 100vw;
  }

  .boxtext {
    font-size: 10px;
  }

  .services .row1-container {
    width: 95vw;
    z-index: 3;
    flex-wrap: wrap;
    place-content: center;
    place-items: center;
    margin: 0 auto;
    display: flex;
    position: relative;
  }

  .modal-contentlog {
    width: 95vw;
    margin-top: 45px;
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    top: -50%;
  }

  to {
    opacity: 1;
    top: 45%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*# sourceMappingURL=index.21ad7cec.css.map */
