/*
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*/

@font-face {
  font-family: remixicon;
  src: url("remixicon.eot?t=1666345859450"); /* IE9 */
  src: url("remixicon.eot?t=1666345859450#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("remixicon.woff2?t=1666345859450") format("woff2"),
    url("remixicon.woff?t=1666345859450") format("woff"),
    url("remixicon.ttf?t=1666345859450") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url("remixicon.svg?t=1666345859450#remixicon") format("svg"); /* iOS 4.1- */

  font-display: swap;
}

[class^="ri-"],
[class*="ri-"] {
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -0.075em;
}

.ri-xxs {
  font-size: 0.5em;
}

.ri-xs {
  font-size: 0.75em;
}

.ri-sm {
  font-size: 0.875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-search-eye-line::before {
  content: "\f0cf";
}

.ri-question-line::before {
  content: "\f045";
}

.ri-settings-4-line::before {
  content: "\f0e8";
}

.ri-user-star-line::before {
  content: "\f276";
}

.ri-login-circle-fill::before {
  content: "\eed5";
}

.ri-draft-fill::before {
  content: "\ec5b";
}

.ri-linkedin-box-fill::before {
  content: "\eeb3";
}

.ri-facebook-box-fill::before {
  content: "\ecb8";
}

.ri-instagram-fill::before {
  content: "\ee65";
}

.ri-vimeo-fill::before {
  content: "\f289";
}

.ri-youtube-fill::before {
  content: "\f2d4";
}

.ri-arrow-down-circle-fill::before {
  content: "\ea49";
}

.ri-arrow-up-circle-fill::before {
  content: "\ea71";
}

.ri-arrow-down-circle-line::before {
  content: "\ea4a";
}
